.tab-label{
    font-size: 1.4rem !important;
    text-transform: capitalize !important;
}

.customer-container {
    display: flex;
    margin-top: 5rem;
    margin-bottom: 0;
}

.customer-display-container {
    width: 100%;
    margin-top: 3rem;
}

.customer-left-container {
    width: 225px;
    margin-left: 1rem;
}
@media (max-width: 600px) {
    .customer-container {
        flex-direction: column;
    }
    .customer-left-container {
        width: 100%;
        height: fit-content;
    }
}