


.wd-login-container{
    background-color: #F5F5F5;
   
}

.login-full-height{
    height: 100vh;
}

.wd-register-item{
    width: 100%;
}

.wd-login-item{
    width: 100%;
}

.wd-login-container-wrapper{
   
}



.wd-submit-btn-login {
   /* background-color: black !important; */
   text-align: center;
   /* color: #F5F5F5 !important; */;
   height: 60px;
   width: 100%;
   border: none !important;
   font-size: 20px;
   font-weight: bolder !important;
   margin: 20px 0;
}

.login-text-center{
    text-align: center;
}



.gradient-custom-1 {
  /* fallback for old browsers */
  background: #fccb90;

  /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-linear-gradient(to right, #ee7724, #d8363a, #dd3675, #b44593);

  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background: linear-gradient(to right, #ee7724, #d8363a, #dd3675, #b44593);
} 

.gradient-custom-2 {
       /* fallback for old browsers */
       /* background: #6a11cb; */
  
       /* Chrome 10-25, Safari 5.1-6 */

       /* background: -webkit-linear-gradient(to bottom, rgba(106,17,203,1), rgba(0,50,138,1)); */
     
       /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
       /* background: linear-gradient(to bottom, rgba(106,17,203,1), rgba(0,50,138,1)) */

       /* fallback for old browsers */
    background: #0062b2;
  
    /* Chrome 10-25, Safari 5.1-6 */
    background: -webkit-linear-gradient(to top, rgba(0,98,178,1), rgba(248,25,65,1));
  
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    background: linear-gradient(to top, rgba(0,98,178,1), rgba(248,25,65,1))

    
       /* background: #2f6bb3; */
  
       /* Chrome 10-25, Safari 5.1-6 */
       /* background: -webkit-linear-gradient(to top, rgba(47,107,179,1), rgba(223,98,59,1)); */
     
       /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
       /* background: linear-gradient(to top, rgba(47,107,179,1), rgba(223,98,59,1)) */
  }

@media (min-width: 768px) {
  .gradient-form {
    
  }
}
@media (min-width: 769px) {
  .gradient-custom-2 {
    border-top-right-radius: .3rem;
    border-bottom-right-radius: .3rem;
  }
}

.login-full-size{
    width: 100%;
    margin-right: 0px !important;

}
.al-center{
    align-items: center;
   
}

.login-full-width{
    width: 100%;
}
.other-field-css {
  width: 100%;
  margin-top: 1rem;
  padding-left: 1.5rem;
}
.login-header {
  margin-bottom: 2rem;
  text-align: center;
}
.login-page-logo {
  width: 600px;
}

@media (max-width: 600px) {
  .login-page-logo {
    width: 350px;
  }
  .login-page-logo-tagline {
    margin: 0%;
    padding: 0%;
    text-align: center;
  }
  .login-header {
    margin: 0%;
    padding: 0%;
  }
  .login-header {
    text-align: center; /* Optional: Center the content */
  }
  
  .login-page-logo {
    display: block; /* Ensure the image takes up its own line */
    margin: 0 auto; /* Optional: Center the image */
  }
  
  .login-header .homepage-logo-tagline {
    font-size: 1rem; 
    text-align: center;
    margin: 0%;
    padding: 10% 0% 3% 0%;
  }
  .login-full-height {
    display: none;
  }
}