.wd-form-header{
    color: #00094b;
}

.wd-submit-btn{
    
        font-weight: bold !important;
        border: 1px solid blue !important;
}

.wd-submit-btn :hover {
    background-color: blue !important;
    color: #FFF !important;
}

.main-container {
  background-color: #F5F5F5;
  height: 100%;
  flex: 1;
}

