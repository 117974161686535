.summary-container {
  background-color: #ECF1F2; 
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.summary-container p {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.summary-container h4 {
  font-size: 24px;
  color: #006EBE; 
}
.small-note {
  font-size: 12px; 
  color: #555; 
}