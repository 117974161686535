/* .homepage-map{
    height: 200px !important;
    width: 100px !important;
} */


.homepage-container{
  margin-top: 5rem;
}

.homepage-logo-tagline{
  padding-left: 10rem;
  font-size: 1.2rem;
  margin-top: -2.3rem;
 
}

.homepage-card-image{
  display: flex;
  align-items: center;
}

.homepage-card-and-data-container {
  width: 100%;
  padding: 1rem 3rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}

.homepage-top-container {
  display: flex;
  flex-direction: column;
  
  align-items: center;
  
  padding: 3rem;
}

.home-container{
  /* height: 100vh; */
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.home-map-container{
  margin-top: 2rem;
  display: flex;
  justify-content: center;
}



.home-page-spotlight{
    /* padding: 5%;
    margin-left: 2%;
    margin-right: 2%; */

    color: #FFF;
   width: 100%;
   
    /* margin-top: 30px; */
}

.homepage-card {
    /* Add shadows to create the "card" effect */
    /* box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2); */
    transition: 0.3s;
    /* background-color: rgb(108, 180, 238, 0.1); */
    border-radius: 24px;
    /* border: #000 1px solid; */
    text-align: center;
    color: white;
    display: flex;
    padding: 1rem 1rem;
    
  }
  
  /* On mouse-over, add a deeper shadow */
  /* .homepage-card:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  } */
  
  /* Add some padding inside the card container */
  .number-cards {
    padding: 2px 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }

  .number-text{
    /* font-weight: bold; */
    padding-bottom: 5px;
    font-size: 1.5rem;
  }

  .homepage-card.img {
   width: 100%;
  }

  .homepage-card-container{
    display: flex;
    flex-direction: row;
    gap: 3rem;
    flex-wrap: wrap;
    margin-left: 2%;
    margin-right: 2%;
    justify-content: space-between;
    margin-top: 2rem;
    margin: 1rem 0 2rem 2%;
    padding: 0.5rem 0 0.5rem 0.5rem
  }

  .homepage-round-button{
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.1);


  }
  .wd-50{
    width: 50%;

  }
  .datahub-card-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 65%;
  }


.datahub-card {
  transition: 0.3s;
  background-color: rgb(108, 180, 238, 0.1);
  border-radius: 15%;
  color: #404040;
  text-align: center;
  height: 200px;
  width: 300px;
  margin: 2px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.datahub-text{
  padding: 0 15px;
  text-align: center;
  text-transform: inherit;
  font-weight: 300;
}

.datahub-btn {
  color: blue !important;
  font-weight: bold !important;
  border: 1px solid blue !important;
}

.datahub-btn:hover {
  background-color: blue !important;
  color: #FFF !important;
}

  
.home-page-search{
  padding: 0.5rem 0 0.5rem 0.5rem;
  margin: 1rem 0 2rem 2%;
  /* width: 75%; */
  /* border-color: red; */
  flex: 1;
}

.home-page-search-icon{
  color: #00094b !important;
}

.home-page-search-btn-container{
  padding: 0.5rem 0.5rem 0.5rem 0;
  margin: 1rem 2% 2rem 0;
  display: flex;
  justify-content: center;
}

.home-page-search-btn{
  border-radius: 0 !important;

  background-color: rgb(0, 98, 178) !important;
  font-weight: bold  !important;
}

.home-page-search-btn:disabled{
  border-radius: 0 !important;
  background-color: #C0C0C0 !important;
  color: #696969 !important;
  font-weight: bold  !important;
}

.home-page-search-text-field{
  background-color: #FFF;
  border:none !important;
}

.home-page-search-auto-complete{
  border-radius: 0;
  /* border-color: red !important; */
  /* border: none !important; */
}

.home-page-spotlight-header{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
}

.datahub-card-advanced-search{
  
  text-align: center;
  color: #404040;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}


.cl-blue{
  background-color: rgb(0,98,178);
}

.cl-orange{
  background-color: rgb(253,102,45);
}

.cl-red{
  background-color: rgb(248,25,65);
}

.home-page-spotlight-heading{
  font-weight: 5rem;
  font-size: 5rem;
}

.home-page-spotlight-content{
  margin-left: 20%;
  margin-right: 20%;
  padding-bottom: 1.1rem;
}

.supplytrace-info-container{
  display: flex;
  background-image: url(../../../public/homepage/Network\ Graphic.png);
  flex-direction: column;
  gap: 2rem;
  background-color: #F5F5F5;
  padding: 5%;
  margin-top:5%;
  color:dimgray;
}

.supplytrace-info-header{
  font-size: 5rem;
  font-weight: bold;
  color: rgb(0,98,178)
}

.supplytrace-info-content{
  font-size: 1.1rem;
  
}

.supplytrace-info-heading{
  width: 45%;
  padding-left: 5rem;
  margin-bottom: 2rem;
}

.supplytrace-info-card{
  display: flex;
  
  /* gap: 5rem; */
  gap: 2rem;
  
  justify-content: center;
  
}

.info-card-top-container{
  background-color: white;
  padding: 1rem;
  margin: 2rem;
  display: flex;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.1);
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 2px;
  width: 33%;
}

.info-card-left-container{
  background-color: white;
  padding: 1rem;
  display: flex;
  margin: 2rem;
  width: 33%;
  justify-content: center;
  align-items: center;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.1);
  gap: 2px;
}

.info-card-heading{
  font-weight: bold;
  text-align: center;
  margin-bottom: 3%;
  font-size: 1.2rem;
}

.info-card-text{
  padding: 1rem;
  font-weight: 300;
  font-size: 0.9rem;
}
.datahub-card-advanced-search-button {
  padding-left: 10px; /* Space between text and button */
}
@media (max-width: 600px) {
  .homepage-top-container {
    padding: 1.5rem;
  }
  .homepage-header {
    padding: 0%;
    margin: 0%;
  }
  .homepage-header img {
    width: 350px;
  }

  .homepage-header .homepage-logo-tagline {
    font-size: 1rem; 
    text-align: center;
    margin: 0%;
    padding: 10% 0% 3% 0%;
  }
  .homepage-card-and-data-container {
    padding: 0%;
  }
  .datahub-card-container {
    width: 100%;
  }
  .datahub-card-advanced-search {
    flex-direction: column;
    text-align: center;
  }

  .datahub-card-advanced-search-button {
    margin-left: 0;
    margin-top: 10px; /* Space between text and button */
  }
  .homepage-card-container {
    margin-top: 10%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  .home-page-search {
    width: 100%;
  }
  .home-page-spotlight-heading {
    font-size: 3rem;
  }
  .home-page-spotlight-content {
    margin-left: 4%;
    margin-right: 4%;
    padding: 15px;
    text-align: justify;
    font-size: 1.2rem;
  }
  .supplytrace-info-container{
    padding: 0%;
    margin: 2% 0%;
    gap: 1rem;
  }
  .supplytrace-info-header{
    font-size: 2.5rem;
    width: 100%;
  }
  .supplytrace-info-heading {
    width: 90%;
  }
  .supplytrace-info-content {
    text-align: justify;
  }
  .supplytrace-info-card {
    gap: 1rem;
    flex-direction: column;
  }
  .info-card-top-container, .info-card-left-container {
    width: 90%;
    margin-right: 1%;
  }
  .info-card-text {
    font-size: medium;
    text-align: center;
  }
} 