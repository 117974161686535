.login-header {
    margin-bottom: 2rem;
    text-align: center;
  }
  .homepage-logo-tagline{
    padding-left: 10rem;
    font-size: 1.2rem;
    margin-top: -2.3rem;
   
  }
  .login-overlay {
    position: absolute;
    top: 1;
    left: 5;
    width: 90%;
    height: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(3px);
    z-index: 10;
    /* background-color: rgba(255, 255, 255, 0.5); */
  }
  
  .login-form {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 60%;
  }
  
  .profile-picture {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-bottom: 20px;
  }
  
  .login-button {
    background-color: #0073b1;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 1rem;
    width: 50%;
  }
  
  .google-login-button {
    background-color: white;
    color: black;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
    margin-top: 10px;
  }
  
  .divider {
    margin: 20px 0;
    font-size: 14px;
    color: #888;
  }
  
  a {
    color: #0073b1;
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: underline;
  }