.supplier-map-filter-main-container {
    width: 100%;
    display: flex;
    margin: 0 2rem;
    background-color: #ECF1F2;
    border-radius: 1rem;
    padding: 1rem;
    justify-content: space-between;
    align-items: center;
}

.supplier-map-filter-main-expanded-container {
    width: 95%;
    display: flex;
    margin: 0 2rem;
    background-color: #ECF1F2;
    border-radius: 1rem;
    flex-direction: column;
    /* padding: 1rem; */
}

.supplier-filter-container {
    /* background-color: #f5f8f9; */
    padding: 0.5rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
}

.supplier-filter-header {
    font-size: 1.6rem;
    padding: 1rem 1rem 1rem 0;
    display: flex;
    justify-content: left;
    align-items: center;
    color: #00094b;
    font-weight: bold;
}

.supplier-filter-header-text {
    padding-left: 10px;
}

.supplier-filter-name-text {
    font-weight: bold;
    padding: 10px;
    color: #000C66;
}

.supplier-filter-divider {
    border: 0.5px solid #D8D8D8;
    margin-top: 1rem;
}

.supplier-tiers-filter {
    width: 100px;
    margin: 0.5rem;
}

.supplier-countries-filter {
    width: 250px;
    margin: 0.5rem;
}

.supplier-suppliers-filter {
    width: 250px;
    margin: 0.5rem;
}

.selected-suppliers-container {
    padding: 1rem 0rem 1rem 0rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
}

.selected-supplier-container {
    margin: 0.5rem 0 0.5rem 0.5rem;
    width: fit-content;
}

.supplier-apply-filters-btn-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    /* margin: 2rem 1rem; */
}

.supplier-apply-filters-btn {
    border-radius: 1.5rem !important;
    height: fit-content;
    width: 150px;
    padding: 0.5rem !important;
    background-color: #00094b !important;
    font-weight: bold !important;
}

.clear-supplier-filters-btn {
    border-radius: 1.5rem !important;
    height: fit-content;
    width: 100px;
    padding: 0.5rem;
    margin-right: 1rem !important;
    border-color: #00094b !important;
    color: #00094b !important;
    font-weight: bold !important;
}

.supplier-filters-btn {
    color: #00094b !important;
    height: fit-content;
    width: fit-content !important;
    border: 1px solid #ECF1F2 !important;
    background-color: #ECF1F2 !important;
    font-weight: bold !important;
}

.supplier-filter-secondary-container {
    padding: 0 1rem;
}

.supplier-filter-secondary-expanded-container {
    background-color: #ECF1F2;
    padding: 0 1rem 1rem 1rem;
}