
.no-suppliers{
  color: #D0D0D0;
  font-size: 4em;
  font-style: italic;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  height: 50vh;
  width: 95%;
  word-wrap: break-word;
  padding: 2rem;
}

.tier-card {
  width: 45%;
  margin: 2rem;
  /* background-color: #f6f6f6; */
  position: relative;
  height: auto;
  border-radius: 1rem;
  border: 1px solid #f6f6f6;
  background-color: #D9E4EC;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.tier-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 3rem;
  background-color: #ECF1F2;
  border-radius: 1rem;
}

.tier-countries-container {
  padding: 1rem;
  padding-top: 3rem;
  background-color: #f6f6f6;
}

.tier-countries-header{
  font-size: 1.5rem;
  padding-bottom: 1rem;
  font-weight: bold;
  color: #000C66;
}

.tier-countries{
  display: flex;
  flex-wrap: wrap;
  padding-left: 1rem;
}

.tier-country{
  margin: 0.2rem;
  font-weight: 100;
}

.tier-number {
  position: absolute;
  top: -18px;
  left: 0px;
  font-size: 18px;
  background-color: #0071e2;
   /* #7393B3; */
  padding: 0.5rem;
  color: #FFF;
  font-weight: bold;
  min-width: 5rem;
  text-transform: uppercase
}
.tier-number-red {
  background-color: red;
}
.tier-suppliers-container {
  padding: 1rem;
}

.tier-suppliers{
  padding-left: 1rem;
  color: #3a3b3c;
}

.tier-suppliers-header{
  font-size: 1.5rem;
  padding-bottom: 1rem;
  font-weight: bold;
  color: #000C66;
}

.tier-supplier-address{
  font-size: 0.8rem;
  font-weight: 200;
}

.tier-supplier-container{
  padding: 0.2rem 0;
}

.tier-supplier-more-text{
  color: gray;
  font-size: 14px;
}

.learn-more-btn{
  width: 100%;
  background-color: #000C66 !important;
  color: #FFF !important;
}
.learn-more-btn:hover{
  width: 100%;
  background-color: #000C66 !important;
  color: #FFF !important;
}



.supplier-map {
  padding: 2rem 2rem 4rem 2rem;
  background-color: rgb(71, 73, 115, 0.05);
  border-radius: 1rem;
}

.year-drop-down-container{
  display: flex;
  margin: 0.5rem;
  height: fit-content;
}

.supplier-main-container {
  display: flex;
  flex-direction: column;
}

.supplier-component-filter-container {
  width: 100%;
  /* background-color: #f5f8f9; */
  display: flex;
  align-items: center;
}

.supplier-data-container {
  margin: 2rem 0rem 2rem 2rem;
  
}

.supplier-tab-header-container{
  display: flex;
  justify-content: space-between;
  padding: 1rem;
}

.supplier-map-container-suspicious-filter {
  background-color: #FFF;
  display: flex;
  width: fit-content;
  padding: 0.5rem;
  border-radius: 0.5rem;
  margin: 0.5rem;
  height: fit-content;
  margin-left: auto;
}

.supplier-map-header-container {
  display: flex;
  color: #00094b;
  align-items: center;
  margin: 1rem 0;
}

.supplier-map-header-text{
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
}