.location-map-plot {
    border: 1px solid lightgray;
    margin: 1rem;
  }

  .locs-map-header-container {
    display: flex;
    color: #00094b;
    align-items: center;
    margin: 1rem 0;
  }
  .locs-map-header-text{
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
  }
  .locs-map-container-suspicious-filter {
    background-color: #FFF;
    display: flex;
    width: fit-content;
    padding: 0.5rem;
    border-radius: 0.5rem;
    margin: 0.5rem;
    height: fit-content;
    margin-left: auto;
  }
  .locs-filter-name-text {
    font-weight: bold;
    padding: 10px;
    color: #000C66;
}
.map-locs-component-reset-zoom{
    margin-bottom: 2rem;
    justify-content: end;
    padding-right: 15px;
    display: flex;
    
}

.map-locs-component-reset-zoom-button{
    color: #00094b !important;
    font-weight: bold !important;
    border-color: #00094b !important;
}
.locs-map {
  margin: 1rem;
  margin-left: 0rem;
  padding: 2rem 2rem 4rem 2rem;
  background-color: rgb(71, 73, 115, 0.05);
  border-radius: 1rem;
}
.company-header-address-text {
  /* max-width: 180px;
  word-wrap: break-word; */
  padding-left: 1rem;
  text-align: start;
}
.company-header-name-container {
  font-weight: bold;
  /* padding: 1rem; */
  font-size: 1.5rem;
  color: #00094b;
  display: flex;
  align-items: center;
  padding-bottom: 0;
}
.company-header-address-container {
  font-weight: bold;
  /* padding: 10px; */
  font-size: 0.9rem;
  color: #00094b;
  display: flex;
  align-items: center;
  
}
.custom-cell {
  padding: 8px; /* Padding for cells */
  /* border: 1px solid #000000; Black border for cell dividers */
}