

.gap-1-rem{
  gap:1rem;
}
.top-nav {

  background-color: rgb(0, 98, 178) !important;
  color: #FFF;

 

  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
 
  width: 100%;
  /* height: 80px; */
  
}
/* 
.dropdown-item{
  padding-top: 1rem;
  color: #797474 !important;
  font-size: 1.4rem;
  padding-right: 5rem;
}

.team-logo {
  height: 60px;
} */

/* .navigation-left-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
} */

/* .main-link {
  height: 60px;
  float: left;

  text-decoration: none;
  font-size: 1.2rem;
  color: #fffdfd !important  ;
  
} */

/* .top-nav a:hover {
  text-decoration: none;
  color: currentColor;
}

.main-link-active{
  border-bottom: 2px white solid;
} */

/* .top-nav a.active {
  text-decoration: none;
  color: #FFF!important;
  display: flex;
  border-bottom: 2px white solid;
  justify-content: center;
  align-items: center;
} */

/* .nav a.active {
  text-decoration: none;
  color: #FFF!important;
  display: flex;
  border-bottom: 2px white solid;
  justify-content: center;
  align-items: center;
} */

/* 
.top-nav a:link {
  text-decoration: none;
  color: currentColor;
} */

/* .top-nav a.nav-link:hover {
  text-decoration: none;
  color: currentColor;
} */

/* .top-nav a.nav-link {
  float: right;
  margin-left: 12px;
  color: currentColor;
  display: flex;
  justify-content: center;
  align-items: center;
} */

/* .team-logo-container {
  font-size: 2rem;
 
  display: flex;
  align-items: center;
} */

/* .team-logo-team-text {
  font-weight: 240;
  padding: 0 10px;
  color: #FFF;
  background-color: rgb(0, 98, 178);;
} */

/* .team-logo-text-bold{
  font-weight: bold;
} */

/* .team-logo-second-container {
  font-size: 30px;
  font-weight: bold;
  color: blue; 
}*/

/* .nav-bar-profile {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #b8b8b8;
  cursor: pointer;
  padding-left: 1rem;
  font-size: 1.2rem;
} */

/* .nav-bar-username {
 padding-left: 0.25rem;
} */
.logo-style {
  
  font-size: 32px;
  padding: 3px;
  color: white;
}
@media only screen and (max-width: 620px) {
  /* Adjust font size for screens smaller than 600px (typical mobile devices) */
  .logo-style {
    font-size: 20px;
  }
}
.navbar .navbar-brand,
.navbar .nav-link,
.navbar .navbar-toggler-icon,
.navbar .dropdown-toggle {
  color: #fff !important;
}
.navbar-brand {
  width: 30%;
}
.nav-container {
  background-color: rgb(0, 98, 178) !important;
  color: #fff !important;

  /* box-shadow: 0 0 8px 0 #fff; */
  width: 100%;
  display: flex;
  font-size: 1.2rem;
}

.color-red {
  color: red;
}
.custom-toggler {
  border: none;
  background: none;
}

.custom-toggler .navbar-toggler-icon {
  background-image: none;
  width: 24px;
  height: 18px;
  position: relative;
}
.centred {
  text-align: left;
  width: 50%;
}
.custom-toggler .navbar-toggler-icon::before,
.custom-toggler .navbar-toggler-icon::after,
.custom-toggler .navbar-toggler-icon div {
  content: '';
  display: block;
  width: 100%;
  height: 2px;
  margin: 0%;
  background-color: #fff;
  position: absolute;
  transition: all 0.3s;
}

.custom-toggler .navbar-toggler-icon::before {
  top: 0;
}

.custom-toggler .navbar-toggler-icon::after {
  bottom: 0;
}

.custom-toggler .navbar-toggler-icon div {
  top: 50%;
  transform: translateY(-50%);
}

.custom-toggler.cross-icon .navbar-toggler-icon::before {
  transform: rotate(45deg);
  top: 50%;
}

.custom-toggler.cross-icon .navbar-toggler-icon::after {
  transform: rotate(-45deg);
  bottom: 50%;
}

.custom-toggler.cross-icon .navbar-toggler-icon div {
  transform: scale(0);
}

@media (max-width: 600px) {
  .navbar-brand {
    width: 100%;
  }
  .nav-container {
    flex-direction: row;
    transition: all 0.3s;
  }
.nav-container-expanded {
  flex-direction: column;
  gap: 0;
  width: 100%;
}
.custom-toggler {
  width: 10%;
  text-align: center;
  margin-left: auto;
}
button.custom-toggler.cross-icon {
  float: right;
  margin-left: auto;
  width: 10%;
  right: 0;
}

}