.company-left-panel-container {
    height: 90vh;
    background-color: #ECF1F2;
    border-radius: 1rem;
    position: sticky;
    width: inherit;
    top: 4rem;
    text-align: left;
}
.small {
    height: auto;
}
.company-name-container {
    font-weight: bold;
    padding: 1rem;
    font-size: 1.5rem;
    color: #00094b;
    display: flex;
    align-items: center;
    padding-bottom: 0;
}
.company-address-container {
    font-weight: bold;
    padding: 10px;
    font-size: 0.9rem;
    color: #00094b;
    display: flex;
    align-items: center;
    
}
.company-address-text {
    max-width: 180px;
    word-wrap: break-word;
    padding-left: 1rem;
    text-align: start;
}
.company-name-text {
    padding-left: 0.7rem;
}

.company-name-divider {
    border: 0.5px solid #D8D8D8;
    /* margin-top: 1rem; */
}

.company-division-tabs-container {
    height: 100%;
}

.company-division-tab-container {
    display: flex;
    align-items: center;
    padding: 1rem;
    cursor: pointer;
    text-decoration: none;
    color: #00094b;
}

.company-division-tab-text {
    padding: 0.4rem;
}

.company-division-tab-selected-container{
    background-color: #00094b;
    color: #FFF;
    display: flex;
    align-items: center;
    padding: 1rem;
    cursor: pointer;
    text-decoration: none;
}

.company-division-tab-selected-container.a:link {
    color: #FFF;
}

.company-division-tab-selected-container.a:visited {
    color: #FFF;
}


.company-division-tab-selected-container.a:hover {
    color: #FFF;
}

.company-division-tab-selected-container.a:active {
    color: #FFF;
}

.company-division-tab-container.a:link {
    color: #00094b;
}

.company-division-tab-container.a:visited {
    color: #00094b;
}


.company-division-tab-container.a:hover {
    color: #00094b;
}

.company-division-tab-container.a:active {
    color: #00094b;
}
@media (max-width: 600px) {
    .company-left-panel-container {
        width: 100%;
        height: fit-content;
    }
}